@import 'variables';

.collapse-experts-review {
  padding: 13px 20px;
  margin: 8px 0;
  &:hover {
    background-color: #E6E6E6;
  }
  &-title {
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: 8px;
  }
  &-info {
    display: flex;
    &-wrap {
      width: calc(100% - 180px);
    }
  }
  &-text {
    font-style: italic;
    font-size: 1.6rem;
    padding-right: 10px;
  }
  &-apply {
    width: 340px;
    padding: 0 10px;
  }
  &-solution {
    font-weight: 600;
    font-size: 1.6rem;
    margin-bottom: 8px;
  }
  &-stars {
    display: flex;
    vertical-align: middle;
    &-wrap {
      display: flex;
      margin-right: 4px;
      img {
        height: 20px;
        width: 20px;
        margin-right: 4px;
      }
    }
  }
  &-rate {
    display: inline-block;
    vertical-align: middle;
    font-weight: bold;
    font-size: 1.6rem;
    margin-left: 10px;
  }
}

.expert_message{

  font-size: 1.8rem;
  margin-top: 10px;
  border-radius:  10px;

}


.expert_info_header{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.buttons__container{
  display: flex;
  
  & > .custom-button{
    margin-right: 10px;
  }
}

.expert__counter{
  display: flex;
}
.expert__item{
  margin-right: 10px;
}